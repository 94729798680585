import React from 'react'
import Layout from '../components/layout'

const TestimonialsPage = () => {
  return (
    <Layout pageTitle="Testimonials" title="MRC Agrotech Ltd - Testimonials">
      <div className="row justify-content-center">
        <div className="col-12 col-lg-8">
          Under Construction
        </div>
      </div>
    </Layout>
  )
}

export default TestimonialsPage
